import React from "react";

export const Contact = (props) => {
  const now = new Date();

  const year = now.getFullYear();

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{props?.data?.title ?? ""}</h2>
                <p></p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            {/* Instagram */}
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-instagram"></i> Instagram
                </span>{" "}
                <a href="https://www.instagram.com/">@</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; Copyright Spiritual Pathways {year} </p>
        </div>
      </div>
    </div>
  );
};
