import React from "react";

export const WhatWeDo = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>{props?.data?.title ?? ""}</h2>
        </div>
        <div className="row">
          {props?.data?.sections?.map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-xs-6 col-md-4">
              {" "}
              <i className={d.icon}></i>
              <h3>{d.title}</h3>
              <p>{d.text}</p>
            </div>
          )) ?? "Loading..."}
        </div>
      </div>
    </div>
  );
};
